import React, { useEffect, useState } from "react"
import s from "./Login.module.css"
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import { LoginThunk } from "../../store/reducers/ActionCreators"
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom"
import { DETAILS, OPEN_ACCOUNT, FORGOT_PASSWORD } from "../../router/Constants"
import CustomInput from "../../components/ui/customInput/CustomInput"
import CustomBtnPink from "../../components/ui/customBtnPink/CustomBtnPink"
import { useTranslation } from "react-i18next"
import { motion } from "framer-motion"
import closeIcon from "../../assets/img/close.png"

const Login = () => {
  const { t } = useTranslation()

  const authStatus = useAppSelector(state => state.userReducer.error)
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [loginData, setLoginData] = useState({
    login: "",
    password: "",
  })
  const [error, setError] = useState("")
  const [validationErrors, setValidationErrors] = useState({
    username: "",
    password: "",
  })

  useEffect(() => {
    if (authStatus === 200) {
      navigate(DETAILS)
    }
  }, [authStatus])

  // login validation function
  const validateFields = () => {
    const errors = {
      username: "",
      password: "",
    }
    if (!loginData.login) {
      errors.username = "Username field is required"
    }
    if (!loginData.password) {
      errors.password = "Password field is required"
    }
    setValidationErrors(errors)
    return !errors.username && !errors.password
  }

  const handleLogin = async () => {
    setError("") // delete old error msg when login again
    if (!validateFields()) {
      return // Prevent dispatch if validation fails
    }
    const respond = await dispatch(LoginThunk(loginData))
    if (respond?.status === 500) {
      setError("System error, please try again later")
      return
    }
    setError(respond?.data) // set error msg to show
    navigate(location.state?.from?.pathname || "/personal_account")
  }

    // navigate to presonal account page if user logged in
    const userToken = useAppSelector(state => state.userReducer.user.userToken);
    if(userToken){
      return <Navigate to={DETAILS}/>
    }

  return (
    <div className={s.wrapper}>
      <motion.div
        initial={{ opacity: 0, scale: 1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className={s.motion_div}>
        <div className={s.content}>
          {error && (
            <div className={s.error}>
              <span>{error}</span>
              <img src={closeIcon} alt="close icon" onClick={() => setError("")} />
            </div>
          )}
          <div className={s.title}>{t("login")}</div>
          <div className={s.inputs_block}>
            <div className={s.login_input_block}>
              <CustomInput
                id={"login"}
                name={"login"}
                type={"text"}
                onChange={e =>
                  setLoginData({
                    ...loginData,
                    login: e.currentTarget.value,
                  })
                }
                value={loginData.login}
                placeholder={`${t("login")} *`}
                required
              />
              {validationErrors.username && <span className={s.error_text}>* {validationErrors.username}</span>}
            </div>
            <div className={s.password_input_block}>
              <CustomInput
                id={"password"}
                name={"password"}
                type={"password"}
                onChange={e =>
                  setLoginData({
                    ...loginData,
                    password: e.currentTarget.value,
                  })
                }
                value={loginData.password}
                placeholder={`${t("password")} *`}
                password
                required
              />
              {validationErrors.password && <span className={s.error_text}>* {validationErrors.password}</span>}
            </div>
            <span className={s.forgot_password}>
              <Link className={s.link} to={FORGOT_PASSWORD}>
                {t("forgot_password")}
              </Link>
            </span>
            <span className={s.link_block}>
              {t("dont_have_account")}
              <Link className={s.link} to={OPEN_ACCOUNT}>
                {" "}
                {t("sign_up")}
              </Link>
            </span>
            <div className={s.btn_block}>
              <CustomBtnPink
                title={t("login")}
                onClick={() => {
                  handleLogin()
                }}
              />
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export default Login
