import React, {useEffect, useState} from 'react';
import './App.css';
import {Navigate, Outlet, Route, Routes, useLocation} from "react-router-dom";
import {
    LOGIN, MOBILE_LEGAL_INFORMATION, OPEN_ACCOUNT,
    PERSONAL_ACCOUNT, FORGOT_PASSWORD,
} from "./router/Constants";
import PersonalAccount from "./PersonalAccount/PersonalAccount";
import Details from "./PersonalAccount/Details/Details";
import Home from "./publicPages/home/Home";
import PublicRoutes from "./router/PublicRoutes";
import Login from "./publicPages/login/Login";
import {privateRoutes} from "./router/PrivateRoutes";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import OpenAccount from "./publicPages/openAccount/OpenAccount";
import MobileMenu from "./components/mobileMenu/MobileMenu";
import MobileLegalInformation from "./publicPages/mobileLegalInformation/MobileLegalInformation";
import ForgotPassword from './publicPages/forgotPassword/ForgotPassword';
import { useAppSelector } from './hooks/redux';


type RouteType = {
    path: string;
    title: string;
    component: JSX.Element;
};

const ProtectedRoute = () => {
  const userToken = useAppSelector(state => state.userReducer.user.userToken);
  const location = useLocation();

  return userToken 
    ? <Outlet /> 
    : <Navigate to={LOGIN} replace state={{ from: location }} />;
};

function App() {
    const publicRoutes: RouteType[] = PublicRoutes()
    const location = useLocation()
    const [isPersonalAccount, setIsPersonalAccount] = useState(true)
    const [isMobileMenuActive, setIsMobileMenuActive] = useState(false)

    useEffect(() => {
        if (location.pathname.includes("personal_account")) {
            setIsPersonalAccount(false)
        } else {
            setIsPersonalAccount(true)
        }
    }, [location.pathname])

    return (
        <div className="App">
            <MobileMenu isActive={isMobileMenuActive} setIsActive={setIsMobileMenuActive}/>
            {
                isPersonalAccount
                    ? <Header setIsMobileMenuActive={setIsMobileMenuActive}/>
                    : <></>
            }
            <Routes>
                <Route index element={<Home/>}/>
                <Route path={LOGIN} element={<Login/>}/>
                <Route path={OPEN_ACCOUNT} element={<OpenAccount/>}/>
                <Route path={MOBILE_LEGAL_INFORMATION} element={<MobileLegalInformation/>}/>
                <Route path={FORGOT_PASSWORD} element={<ForgotPassword />}/>
                
                {
                    publicRoutes.map((routeElement: RouteType) => {
                        return (
                            <Route
                                path={routeElement.path}
                                element={routeElement.component}
                                key={routeElement.path}
                            />
                        )
                    })
                }
                <Route element={<ProtectedRoute/>}>
                  <Route path={PERSONAL_ACCOUNT} element={<PersonalAccount/>}>
                      <Route index element={<Details/>}/>
                      {
                          privateRoutes.map((routeElement) => {
                              return (
                                  <Route
                                      path={routeElement.path}
                                      element={routeElement.component}
                                      key={routeElement.path}
                                  />
                              )
                          })
                      }
                  </Route>
                </Route>
            </Routes>
            {
                isPersonalAccount
                    ? <Footer/>
                    : <></>
            }
        </div>
    );
}

export default App;
